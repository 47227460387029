






































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CsvImportGuide from '@/components/CsvImportGuide.vue';
import MembersUploadSection from './MembersUploadSection.vue';
import { MemberObject } from '../../../utils/group.util';
import { ValidationObserver } from 'vee-validate';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';
import FileUpload from '@/components/FileUpload.vue';
import EditableMemberTable from '@/views/AdminCreateGroup/components/EditableMemberTable.vue';
import GroupCsvImportInfo from '@/views/AdminCreateGroup/components/GroupCsvImportInfo.vue';
import { ToastProgrammatic } from 'buefy';
import { Action } from 'vuex-class';
import { handleUserListUploading } from '@/utils/member-list-upload.util';
import { MyjbiGroupUserAttributeSpec } from '../../../jbi-shared/types/myjbi-group.types';
import { ValidateImportedMemberListPayload } from '../../../store/modules/job-result-data/job-result-data.types';
import { isJobFailure } from '@/utils/bull-queue.util';

export interface MemberParams {
  isRequired: boolean;
  isValid: boolean;
  value: string;
  errorMessage: string;
}

@Component({
  components: {
    CsvImportGuide,
    MembersUploadSection,
    ValidationObserver,
    FileUpload,
    GroupCsvImportInfo,
    EditableMemberTable
  }
})
export default class AddMemberFromFileModal extends Vue {
  @Prop(Array) userAttributes!: MyjbiGroupUserAttributeSpec[];
  @Prop() existingMembers!: MemberObject[];
  @Prop(Array) allowedEmailDomains!: string[];

  public membersFile: File | null = null;
  public membersData: any[] | null = null;
  public notify = false;
  public isLoading = false;
  public isMemberDataValid = false;

  @Action('admin/uploadUserListFile')
  public uploadUserListFile!: (file: File) => Promise<any>;

  @Action('jobResultData/createImportedListValidationJob')
  public validateMemberList!: (
    payload: ValidateImportedMemberListPayload
  ) => Promise<any>;

  $refs!: {
    uploadMemberModal: HTMLDivElement;
  };

  // find email index for duplicate checking
  get emailIndex() {
    return this.userAttributes.findIndex(
      (attr) => attr.groupUserAttribute.slug?.toLowerCase() === 'email'
    );
  }

  public addMembers() {
    this.isLoading = true;
    this.$emit('add', this.membersData);
  }

  @Watch('membersFile')
  @isTruthy
  public async onFilesAdded(file: File) {
    if (!this.membersFile) {
      this.isLoading = false;
      return;
    }

    try {
      const gcsURL = await this.uploadUserListFile(file);
      const job = await this.validateMemberList({
        fileLink: gcsURL,
        emailIndex: this.emailIndex,
        userAttributesSpecs: this.userAttributes.filter(
          (attr) => attr.groupUserAttribute.id
        ),
        emailDomains: this.allowedEmailDomains
      });

      const memberList = await handleUserListUploading.call(
        this,
        job,
        this.$refs.uploadMemberModal
      );

      if (isJobFailure(memberList)) {
        this.membersData = null;
        this.membersFile = null;
      } else {
        this.membersData = memberList as MemberObject[];
      }
    } catch (error) {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }
}
